import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { BASE_URL, getDefaultHeaders, getDynamicHeaders, Entity } from '../config';
import { ITextCommonContent, ITextFAQContent } from './types';

export const textAPI = createApi({
    reducerPath: 'text',
    baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
    endpoints: (build) => ({
        getFaq: build.query<ITextFAQContent[], void>({
            query: () => ({
                url: `${Entity.text}/faq`,
                headers: { ...getDefaultHeaders(), ...getDynamicHeaders() },
            })
        }),
        getOffer: build.query<ITextCommonContent, void>({
            query: () => ({
                url: `${Entity.text}/oferta-website`,
                headers: { ...getDefaultHeaders(), ...getDynamicHeaders() },
            }),
        }),
        getChatGreeting: build.query<ITextCommonContent, void>({
            query: () => ({
                url: `${Entity.text}/new_dialogue`,
                headers: { ...getDefaultHeaders(), ...getDynamicHeaders() },
            }),
        }),
    }),
});