import { lazy } from 'react';

const Dialogs       =       lazy(() => import('pages/Dialogs/Dialogs'));
const Profile       =       lazy(() => import('pages/Profile/Profile'));
const Settings      =       lazy(() => import('pages/Settings/Settings'));
const Gallery       =       lazy(() => import('pages/Gallery/Gallery'));
const Chat          =       lazy(() => import('pages/Chat/Chat'));
const Registration  =       lazy(() => import('pages/Registration/Registration'));
const BlockList     =       lazy(() => import('pages/BlockList/BlockList'));

export enum privatePath {
    dialogs         =       '/dialogs',
    profile         =       '/profile',
    settings        =       '/settings',
    gallery         =       '/gallery',
    chat            =       '/chat/:uuid',
    registration    =       '/registration',
    support         =       '/chat/0',
    blocklist       =       '/blocklist',
}

export const privateRoutes = [
    {
        path: privatePath.dialogs,
        page: <Dialogs/>,
    },
    {
        path: privatePath.profile,
        page: <Profile/>,
    },
    {
        path: privatePath.settings,
        page: <Settings/>,
    },
    {
        path: privatePath.gallery,
        page: <Gallery/>,
    },
    {
        path: privatePath.chat,
        page: <Chat/>,
    },
    {
        path: privatePath.registration,
        page: <Registration/>
    },
    {
        path: privatePath.blocklist,
        page: <BlockList/>
    },
];